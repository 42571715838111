// Generated by Framer (2bdc57c)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, useActiveVariantCallback, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion } from "framer-motion";
import * as React from "react";

const cycleOrder = ["siFrRGg6k", "tKQl3guyY"];

const variantClassNames = {siFrRGg6k: "framer-v-ct38kq", tKQl3guyY: "framer-v-1vefnse"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const humanReadableVariantMap = {"Variant 1": "siFrRGg6k", "Variant 2": "tKQl3guyY"};

const transitions = {default: {damping: 60, delay: 0, duration: 0.3, ease: [0.44, 0, 0.56, 1], mass: 1, stiffness: 500, type: "spring"}};

export interface Props extends React.HTMLAttributes<HTMLDivElement> { layoutId?: string | null; width?: number; height?: number; layoutDependency?: string | number; variant?: keyof typeof humanReadableVariantMap; }

const Component = React.forwardRef<HTMLDivElement, Props>(function({ id, style, className, width, height, layoutId, variant: outerVariant = "siFrRGg6k", ...restProps }, ref) {

const outerVariantId = humanReadableVariantMap[outerVariant];

const variant = outerVariantId || outerVariant;

const {baseVariant, classNames, gestureVariant, setGestureState, setVariant, transition, variants} = useVariantState({cycleOrder, defaultVariant: "siFrRGg6k", transitions, variant, variantClassNames})
const layoutDependency = variants.join("-") + restProps.layoutDependency;

const { activeVariantCallback, delay } = useActiveVariantCallback(baseVariant)

const onMouseEntery9wic8 = activeVariantCallback(async (...args) => {
setVariant("tKQl3guyY")
})

const defaultLayoutId = React.useId()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<motion.div initial={variant} animate={variants} onHoverStart={() => setGestureState({isHovered: true})} onHoverEnd={() => setGestureState({isHovered: false})} onTapStart={() => setGestureState({isPressed: true})} onTap={() => setGestureState({isPressed: false})} onTapCancel={() => setGestureState({isPressed: false})} className={cx("framer-jt3Pg", classNames)} style={{display: "contents"}}>
<motion.div {...restProps} className={cx("framer-ct38kq", className)} data-framer-name={"Variant 1"} data-highlight layoutDependency={layoutDependency} layoutId={"siFrRGg6k"} onMouseEnter={onMouseEntery9wic8} ref={ref} style={{backgroundColor: "rgb(10, 247, 255)", borderBottomLeftRadius: 684, borderBottomRightRadius: 684, borderTopLeftRadius: 684, borderTopRightRadius: 684, ...style}} transition={transition} variants={{tKQl3guyY: {borderBottomLeftRadius: 5, borderBottomRightRadius: 5, borderTopLeftRadius: 5, borderTopRightRadius: 5}}} {...addPropertyOverrides({tKQl3guyY: {"data-framer-name": "Variant 2"}}, baseVariant, gestureVariant)}/>
</motion.div>
</LayoutGroup>)

});

const css = [".framer-jt3Pg [data-border=\"true\"]::after { content: \"\"; border-width: var(--border-top-width, 0) var(--border-right-width, 0) var(--border-bottom-width, 0) var(--border-left-width, 0); border-color: var(--border-color, none); border-style: var(--border-style, none); width: 100%; height: 100%; position: absolute; box-sizing: border-box; left: 0; top: 0; border-radius: inherit; pointer-events: none; }", "@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-jt3Pg .framer-29j8vu { display: block; }", ".framer-jt3Pg .framer-ct38kq { height: 179px; overflow: hidden; position: relative; width: 175px; will-change: transform; }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 179
 * @framerIntrinsicWidth 175
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","fixed"]},"tKQl3guyY":{"layout":["fixed","fixed"]}}}
 */
const FramerYcMttzAV_: React.ComponentType<Props> = withCSS(Component, css, "framer-jt3Pg") as typeof Component;
export default FramerYcMttzAV_;

FramerYcMttzAV_.displayName = "Pallo";

FramerYcMttzAV_.defaultProps = {height: 179, width: 175};

addPropertyControls(FramerYcMttzAV_, {variant: {options: ["siFrRGg6k", "tKQl3guyY"], optionTitles: ["Variant 1", "Variant 2"], title: "Variant", type: ControlType.Enum}} as any)

addFonts(FramerYcMttzAV_, [])